import React, { useEffect } from "react";
import Nav from "../Navbar/Nav";

const AppRoute = ({
  component: Component,
  routeKey,
  blankLayout,
  ...props
}) => {
  return (
    <>
      <Component {...props} />
    </>
  );
};

export default AppRoute;
