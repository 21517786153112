import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./DataSlice";

const store = configureStore({
  reducer: {
  
    data: dataReducer, 
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
