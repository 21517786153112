import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AUTHENTICATED_ENTRY } from '../../Config/AppConfig'

const PublicRoute = () => {

	const token = sessionStorage.getItem('Token')
  
	return token ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet/>
}

export default PublicRoute