import React, {useEffect} from "react";
import './App.css';
import { BrowserRouter } from "react-router-dom";
import Layout from "./Components/LayoutComponents/Layouts";

function App() {
  
  return (
   
        <BrowserRouter >
          <Layout />
        </BrowserRouter>
   
  );
}

export default App;
