import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signedUrl: "",
  imageId: null,
  jsonData: [],
  files: "",
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setSignedUrl: (state, action) => {
      console.log(action.payload);
      state.signedUrl = action.payload;
    },
    setImageId: (state, action) => {
      console.log(action.payload);
      state.imageId = action.payload;
    },
    setJsonData: (state, action) => {
      console.log(action.payload);
      state.jsonData = action.payload;
    },
    setFiles: (state, action) => {
      console.log(action.payload);
      state.files = action.payload;
    },
  },
});

export const { setSignedUrl, setImageId, setJsonData,setFiles } = dataSlice.actions;

export default dataSlice.reducer;
