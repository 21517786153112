import React, { lazy, Suspense } from "react";
import { ConfigProvider } from "antd";
import Routes from "../../Routes";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

function Layout() {
  const token = sessionStorage.getItem("Token");

  return (
    <ConfigProvider>
      <Suspense fallback={<div>Loading...</div>}>
        {token ? (
          <AppLayout>
            <Routes />
          </AppLayout>
        ) : (
          <AuthLayout>
            <Routes />
          </AuthLayout>
        )}
      </Suspense>
    </ConfigProvider>
  );
}

export default Layout;
