import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { 
	AUTH_PREFIX_PATH, 
	UNAUTHENTICATED_ENTRY, 
	REDIRECT_URL_KEY 
} from '../../Config/AppConfig'

const ProtectedRoute = () => {
   const location = useLocation();
   const token = sessionStorage.getItem('Token')
   
   if (!token) {
		return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	}

   return <Outlet />
}

export default ProtectedRoute;