import { useEffect } from "react";

const AuthRoute = ({
  component: Component,
  routeKey,
  blankLayout,
  ...props
}) => {
  return <Component {...props} />
};

export default AuthRoute;
