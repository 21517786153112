import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AUTH_PREFIX_PATH } from "../../Config/AppConfig";
import { Dropdown, Menu, notification } from "antd";
import { UserOutlined, DownOutlined, LogoutOutlined, HomeOutlined } from "@ant-design/icons";
import { CustomAlert, UpdateAlert } from "../Global/CustomAlert";
import digitreeLogo from "../../../src/assets/digitreeLogo.svg";

function Nav({ routeKey, isEditing }) {
  const navigate = useNavigate();
  const username = sessionStorage.getItem("user_name");
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const [showUnsavedAlert, setShowUnsavedAlert] = useState(false);

  const handleLogoutClick = () => {
    if (isEditing) {
      setShowUnsavedAlert(true);
    } else {
      setShowLogoutAlert(true);
    }
  };

  const handleConfirmLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    setShowLogoutAlert(false);

    navigate(`${AUTH_PREFIX_PATH}/login`);
    notification.success({
      message: "Success",
      description: "Logout successful!",
    });
  };

  const handleCancelLogout = () => {
    setShowLogoutAlert(false);
  };

  const handleDismissUnsavedAlert = () => {
    setShowUnsavedAlert(false);
  };

  const menu = (
    <Menu
      style={{
        padding: "10px 20px",
        textAlign: "center",
        borderRadius: "0px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        width: "160px",
      }}
    >
      <Menu.Item
        key="logout"
        icon={
          <LogoutOutlined style={{ fontSize: "18px", marginRight: "10px" }} />
        } // Increased icon size and added margin
        onClick={handleLogoutClick}
        style={{
          fontSize: "16px",
          color: "#ff4d4f",
          display: "flex",
          alignItems: "center",
        }}
      >
        Log Out
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="bg-black h-18 flex items-center justify-evenly sticky top-0 z-50">
        <header className="flex items-center justify-between w-full max-w-screen-3xl mx-auto px-4">
          <div className="flex gap-3 ml-8 items-center justify-center">
          <Link
              to="/dashboard/home"
              className="app_name text-dark-7 text-[17px]"
            >
              <img src={digitreeLogo} alt="App Logo" width="158" height="50" />
            </Link>
          </div>

          <div className="flex items-center space-x-4">
          <Link
            to="/dashboard/home"
            style={{ textDecoration: "none" }}
            className="text-white text-lg font-medium flex items-center ml-6 hover:brightness-50"
          >
            <HomeOutlined className="text-xl mr-2" />
            Home
          </Link>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
              overlayClassName="custom-dropdown"
              className="hover:brightness-50"
            >
              <span className="flex items-center text-white cursor-pointer mr-12 text-lg space-x-2 ">
                <UserOutlined className="text-xl" />
                <span className="text-lg font-medium">{username}</span>
                <DownOutlined className="text-xl ml-1" />
              </span>
            </Dropdown>
          </div>
        </header>
      </div>

      {showUnsavedAlert && (
        <UpdateAlert
          title="Unsaved Changes"
          message="Please save your information before logging out."
          onConfirm={handleDismissUnsavedAlert}
        />
      )}

      {showLogoutAlert && (
        <CustomAlert
          title="Log Out"
          message="Are you sure you want to log out?"
          onConfirm={handleConfirmLogout}
          onCancel={handleCancelLogout}
        />
      )}
    </>
  );
}

export default Nav;
